<template>
  <div>
    <vx-card actionable class="cardx" title="SR SPOC Dashboard">
      <!-- <vs-row type="flex" style="margin-top: 2%">
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="4"
        >
          <v-select
            label="cities"
            :options="cities"
            v-model="selected_city"
            style="width: 240px; z-index: 1000"
          ></v-select>
        </vs-col>
      </vs-row> -->
      <!-- <vx-card actionable class="cardx" style="margin-top: 3%"> -->
         <vx-card actionable class="cardx" >
        <vs-row type="flex" style="margin-top: 2%">
          <vs-col
            vs-order="1"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="6"
            style="font-size: 19px;"
          >
            <b>Call Logs</b>
          </vs-col>
          <vs-col
            vs-order="1"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="6"
            style="font-size: 19px;"
          >
            <b>Work Logs</b>
          </vs-col>
        </vs-row>
        <vs-table :data="sortSpoc">
          <template slot="thead">
            <vs-th>Spoc Name</vs-th>
            <vs-th>Missed Calls</vs-th>
            <vs-th>Untracked</vs-th>
            <vs-th>To Update</vs-th>
            <vs-th>Call Logs Today</vs-th>
            <vs-th>Untapped</vs-th>
            <vs-th>Delays</vs-th>
            <vs-th>Today</vs-th>
            <vs-th>Waiting</vs-th>
            <vs-th>MHPS</vs-th>
            <vs-th>Net Enquiries</vs-th>
            <vs-th>Flag</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].full_name">
                {{ data[indextr].full_name }}
              </vs-td>
              <vs-td :data="data[indextr].first_name">
                <a @click="getData(tr.id, 'missed_calls')">
                  {{ data[indextr].missed_calls }}
                </a>
              </vs-td>
              <vs-td :data="data[indextr].first_name">
                <a @click="getData(tr.id, 'untracked')">
                  {{ data[indextr].untracked }}
                </a>
              </vs-td>
              <vs-td :data="data[indextr].first_name">
                <a @click="getData(tr.id, 'to_update')">
                  {{ data[indextr].to_update }}
                </a>
              </vs-td>
              <vs-td :data="data[indextr].first_name">
                <a @click="getData(tr.id, 'call_logs_today')">
                  {{ data[indextr].call_logs_today }}
                </a>
              </vs-td>
              <vs-td :data="data[indextr].first_name">
                <a @click="getData(tr.id, 'untapped')">
                  {{ data[indextr].untapped }}
                </a>
              </vs-td>
              <vs-td :data="data[indextr].first_name">
                <a @click="getData(tr.id, 'delays')">
                  {{ data[indextr].delays }}
                </a>
              </vs-td>
              <vs-td :data="data[indextr].first_name">
                <a @click="getData(tr.id, 'today')">
                  {{ data[indextr].today }}
                </a>
              </vs-td>
              <vs-td :data="data[indextr].first_name">
                <a @click="getData(tr.id, 'waiting')">
                  {{ data[indextr].waiting }}
                </a>
              </vs-td>
              <vs-td :data="data[indextr].first_name">
                <a @click="getData(tr.id, 'mhps')">
                  {{ data[indextr].mhps }}
                </a>
              </vs-td>
              <vs-td :data="data[indextr].first_name">
                <a
                  @click="
                    getData(
                      tr.id,
                      'net_enquiries',
                      data[indextr].net_enquiries_sorted
                    )
                  "
                  >{{ data[indextr].net_enquiries_sorted.length }}</a
                >
              </vs-td>

              <vs-td :data="data[indextr].first_name">
                <a @click="getData(tr.id, 'flag')">{{ data[indextr].flag }}</a>
              </vs-td>
              <!-- <vs-td :data="data[indextr].first_name"
                ><a @click="getData(tr.id, 'net_enquiries')">{{
                  data[indextr].net_enquiries
                }}</a></vs-td
              >-->
            </vs-tr>
            <vs-tr v-show='hidetotal'>
              <vs-td>
                {{ total.Header }}
              </vs-td>
              <vs-td>
                {{ total.missed_calls }}
              </vs-td>
              <vs-td>
                {{ total.untracked }}
              </vs-td>
              <vs-td>
                {{ total.to_update }}
              </vs-td>
              <vs-td>
                {{ total.call_logs_today }}
              </vs-td>
              <vs-td>
                {{ total.untapped }}
              </vs-td>
              <vs-td>
                {{ total.delays }}
              </vs-td>
              <vs-td>
                {{ total.today }}
              </vs-td>
              <vs-td>
                {{ total.waiting }}
              </vs-td>
              <vs-td>
                {{ total.mhps }}
              </vs-td>
              <vs-td>
                {{ total.net_enquiries }}
              </vs-td>
              <vs-td>
                {{ total.flag }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vx-card>
    </vx-card>
    <SpocDashboardCallLogPopup></SpocDashboardCallLogPopup>
    <SpocDashboardAdminToUpdate></SpocDashboardAdminToUpdate>
    <SpocDashboardAdminWorkLog></SpocDashboardAdminWorkLog>
    <SpocDashboardCmNetEnquiries></SpocDashboardCmNetEnquiries>
    <!-- <SpocDashboardNetEnquiries></SpocDashboardNetEnquiries> -->
  </div>
</template>

<script>
import vSelect from "vue-select";
import axios from "axios";
import constants from "../../constants.json";
import SpocDashboardCallLogPopup from "../components/pagesComponents/SpocDashboardAdminCallLog.vue";
import SpocDashboardAdminToUpdate from "../components/pagesComponents/SpocDashboardAdminToUpdate.vue";
import SpocDashboardAdminWorkLog from "../components/pagesComponents/SpocDashboardAdminWorkLog.vue";
// import SpocDashboardNetEnquiries from "../components/pagesComponents/SpocDashboardNetEnquiries.vue";
import SpocDashboardCmNetEnquiries from "../components/pagesComponents/SpocDashboardCMNetenquiries.vue";
// import SpocDashboardNetEnquiries from "../components/pagesComponents/SpocDashboardNetEnquiries.vue";
import eventbus from "../components/eventbus";
export default {
  mounted() {
    this.spocscopy = [];
    this.cities = [];
    this.spocs = [];
    this.getSpocs();
  },
  data() {
    return {
      hidetotal:false,
      spocscopy: [],
      cities: [],
      sortSpoc: [],
      rawBde: [],
      spocs: [],
      selected_spoc: "",
      selected_city: "",
      cityString: "",
      EnquirySpelling: "Enquiries",
      checkedEnquiries: [],
      SpocId: "",
      SpocName: "",
      MissedCall: "",
      UntrackedCall: "",
      ToUpdate: "",
      CallLogToday: "",
      UntappedData: "",
      DelaysData: "",
      TodayData: "",
      WaitingData: "",
      MhpsData: "",
      NetEnquiries: "",
      total: {
        missed_calls: [],
        untracked: [],
        to_update: [],
        call_logs_today: [],
        untapped: [],
        delays: [],
        today: [],
        waiting: [],
        net_enquiries: [],
        mhps: [],
        flag: []
      },
    };
  },
  components: {
    "v-select": vSelect,
    SpocDashboardCallLogPopup,
    SpocDashboardAdminToUpdate,
    SpocDashboardAdminWorkLog,
    // SpocDashboardNetEnquiries,
    SpocDashboardCmNetEnquiries,
  },
  watch: {
   
  },
  methods: {
    removeUnwwantSpocs(SpocList) {
      let wantedSpocIds = [
        // 178,
        // 179,
        // 181,
        // 182,
        // 186,
        // 187,
        // 188,
        // 189,
        // 190,
        // 200,
        // 56,
        // 111,
        // 69,
        // 192,
        // 84,
        // 83,
        // 191,
        // 193,
        // 43,
        // 103,
        // 135,
        // 133,
        // 134,
        // 89,
        // 94,
        // 54,
        // 195,
        // 76,
        // 75,
        // 100,
        // 180,
        // 96,
        // 176,
        // 174,
        // 194,
        // 196,
        // 99,
        // 46,
        // 197,
        // 80
      ];
      let filteredSpocs = SpocList.filter(function(el) {
        return wantedSpocIds.indexOf(el.id) <= 0;
      });
      return filteredSpocs;
    },

    NetEnquiryaggregator(payload) {
      // console.log(payload);
      // return;
      payload.forEach((data_point) => {
        data_point.mobile = data_point.mobile.split(",");
        data_point.dialingNumber = "";
        if (data_point.mobile.length > 0) {
          data_point.dialingNumber = this.getLastTenDigits(
            data_point.mobile[0]
          );
        }
      });

      var unique = payload
        .map((lead) => {
          return {
            count: 1,
            name: lead.name,
            dialingNumber: lead.dialingNumber,
          };
        })
        .reduce((a, b) => {
          a[b.dialingNumber] = (a[b.dialingNumber] || 0) + b.count;
          return a;
        }, {});

      let mainObj = {};
      payload.forEach((lead) => {
        for (var key in unique) {
          if (unique.hasOwnProperty(key)) {
            if (lead.dialingNumber === key) {
              lead.count = unique[key];
            }
          }
        }
      });
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          mainObj[key] = [];
          payload.forEach((net_enquiry) => {
            if (net_enquiry.dialingNumber === key) {
              mainObj[key].push(net_enquiry);
            }
          });
        }
      }
      let newPayload = [];
      for (var parameter in mainObj) {
        if (mainObj.hasOwnProperty(parameter)) {
          mainObj[parameter];
          newPayload.push(mainObj[parameter][0]);
        }
      }

      return newPayload;

      // console.log(newPayload.length);
    },
    getData(spoc_id, category, NetEnquiriesData) {
      // console.log(spoc_id, category);
      let spocName = null;
      this.SpocName.forEach((spoc) => {
        if (spoc.id === spoc_id) {
          spocName = spoc.first_name;
        }
      });
      if (category === "net_enquiries") {
        eventbus.$emit(
          "spocDashboardAdminCM-NetEnquiries",
          NetEnquiriesData,
          spocName
        );
        return;
      }
      let url = `${constants.ADMIN_SERVER_API}getSRDashboardForAdminDetails`;

      let obj = {
        spoc_id: spoc_id,
        category: category,
      };
      axios
        .get(url, {
          params: obj,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          this.response = response;
          if (category === "missed_calls" || category === "untracked") {
            eventbus.$emit(
              "spocDashboardAdmin-calllog",
              response.data,
              url,
              obj,
              category,
              spocName
            );
          } else if (category === "to_update") {
            eventbus.$emit(
              "spocDashboardAdmin-toUpdate",
              response.data,
              url,
              obj,
              category,
              spocName
            );
          } else if (
            category === "delays" ||
            category === "today" ||
            category === "waiting" ||
            category === "mhps" ||
            category === "untapped" ||
            category === "flag"
          ) {
            this.$store.commit("MOUNT_MWB_LEADS", response.data.data);
            eventbus.$emit("spocDashboardAdmin-WorkLog", [
              response,
              url,
              obj,
              spocName,
            ]);
          }
          // else if (category === "net_enquiries") {
          //   this.$store.commit("MOUNT_MWB_LEADS", response.data.data);
          //   eventbus.$emit("spocDashboardAdminCM-NetEnquiries", [
          //     response,
          //     url,
          //     obj,
          //   ]);
          // }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getSpocs() {
      let url = `${constants.ADMIN_SERVER_API}getSRSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.loggedin = response.data.logged_in_user_id;
          response.data.spocs = this.removeUnwwantSpocs(response.data.spocs);
          this.SpocName = response.data.spocs;
          this.assignLead(this.SpocName)
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    async assignLead(spoc) {
      this.$vs.loading({
        type: "default",
      });
      for (let i = 0; i < spoc.length; i++) {
        let response = await this.getDashbaordStatsSpoc(spoc[i].id);
        spoc[i].missed_calls = response.data.missed_calls;
        spoc[i].untracked = response.data.untracked;
        spoc[i].to_update = response.data.to_update;
        spoc[i].call_logs_today = response.data.call_logs_today;
        spoc[i].untapped = response.data.untapped;
        spoc[i].delays = response.data.delays;
        spoc[i].today = response.data.today;
        spoc[i].waiting = response.data.waiting;
        spoc[i].mhps = response.data.mhps;
        spoc[i].net_enquiries = response.data.net_enquiries;
        spoc[i].flag = response.data.flag;
        spoc[i].net_enquiries_sorted = this.NetEnquiryaggregator(
          response.data.net_enquiries
        );
      }
      this.$vs.loading.close();
      this.sortSpoc = spoc;
      this.totalCalculate(spoc);
    },
    getDashbaordStatsSpoc(spoccount) {
      // let SpocId = spoccount;
      let url = `${
        constants.ADMIN_SERVER_API
      }getSRDashboardForAdmin?spoc_id=${spoccount}`;
      const promise = new Promise((resolve, reject) => {
        axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
            reject();
          });
      });
      return promise;
    },
    totalCalculate(Spocs) {
      this.total.Header = "TOTAL";
      this.total.missed_calls = Spocs.reduce(
        (n, { missed_calls }) => n + missed_calls,
        0
      );
      this.total.untracked = Spocs.reduce(
        (n, { untracked }) => n + untracked,
        0
      );
      this.total.to_update = Spocs.reduce(
        (n, { to_update }) => n + to_update,
        0
      );
      this.total.call_logs_today = Spocs.reduce(
        (n, { call_logs_today }) => n + call_logs_today,
        0
      );
      this.total.untapped = Spocs.reduce((n, { untapped }) => n + untapped, 0);
      this.total.delays = Spocs.reduce((n, { delays }) => n + delays, 0);
      this.total.today = Spocs.reduce((n, { today }) => n + today, 0);
      this.total.waiting = Spocs.reduce((n, { waiting }) => n + waiting, 0);
      this.total.mhps = Spocs.reduce((n, { mhps }) => n + mhps, 0);
      this.total.net_enquiries = Spocs.reduce(
        (n, { net_enquiries_sorted }) => n + net_enquiries_sorted.length,
        0
      );
      this.total.flag = Spocs.reduce(
        (n, { flag }) => n + flag,
        0
      );
      console.log("this.total", this.total);
      this.hidetotal=true;
    },
 
  },
};
</script>

<style>
.vhd-input[data-v-25b15922] {
  min-width: 250px;
  padding: 5px;
  border: 1px solid #eee;
  color: #505050;
  font-size: 16px;
  line-height: 9px;
  border-radius: 8px;
  border-color: lightgray;
  outline: none;
}
.vs--searchable .vs__dropdown-toggle {
  height: 38px;
  font-size: 15px;
}
.vs--single .vs__selected .vs__dropdown-menu {
  font-size: 15px;
}
.vs-table--tbody-table .tr-values td {
  font-size: 13px;
}
</style>
